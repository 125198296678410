window.addEventListener('load', () => {
    document.querySelector('#country-selector').onchange = function() {
        window.location = '/' + this.value + '/index';
    };

    window.ops = window.ops || {};
    window.ops.sso = window.ops.sso || {};
    let site = window.location.pathname.replace(/\/+/g, '/').substr(1, 3);
    let redirectUri = window.location.origin + '/' + site + '/login/';

    window.ops.sso.initiate = function() {
        if (site !== 'ops' && site !== 'usa') {
            site = 'ops';
        }

        let params = ['path=' + encodeURIComponent(window.location.pathname + window.location.search)];
        if (window.location.pathname.includes('login') || window.location.pathname.includes('logout')) {
            params = [`path=${encodeURIComponent(['', site, 'index'].join('/'))}`];
        }

        // if we already know what user is logging in, include it
        let loggedUser = window.localStorage.getItem('ops_sso_user');
        if (loggedUser) {
            params.push(`user=${encodeURIComponent(loggedUser)}`);
        }

        window.location.href = `/${site}/login/sso?${params.join('&')}`;
    };

    let loginText = document.querySelector('.error')?.innerText ?? '';
    if (/Single Sign On failed/.test(loginText)) {
        // SSO failed, clear local storage as that might be part of the issue
        window.localStorage.removeItem('ops_sso_user');
    }

    if (/Logout Successful/.test(loginText)) {
        // Manual logout, clear local storage so they are not trapped silently logging into the user they had before
        window.localStorage.removeItem('ops_sso_user');
    }

    document.getElementById('sso').addEventListener('click', event => {
        event.preventDefault();
        window.ops.sso.initiate();
        return false;
    });

    // since we don't pull in utilities, we have to signal load is done
    window.ops._pyrLoadComplete = true;
});
